import { defineStore } from 'pinia'
import SysMenuService from '../apis/SysMenuService';
import CommonUtils from '../utils/CommonUtils';
import { GlobalConsts } from '../config';

export const useMenuStore = defineStore('mainMenu', {
  state: () => ({
    menus: [],
    permissions: []
  }),
  actions: {
    async setMenus() {
      // const resp = await SysMenuService.getListByUser();
      // if (resp.code == 1) {
      //   this.menus = resp.data.menus;
      //   this.permissions = resp.data.permissions;
      // }
      const menus = CommonUtils.getLocalStore(GlobalConsts.MenusKey);
      const permissions = CommonUtils.getLocalStore(GlobalConsts.PermissionsKey);
      if(menus&&permissions){
        //@ts-ignore
        this.menus = JSON.parse(menus);
        //@ts-ignore
        this.permissions = JSON.parse(permissions);
      }else{
        const resp = await SysMenuService.getListByUser();
        if (resp.code == 1) {
          CommonUtils.setLocalStore(GlobalConsts.MenusKey,JSON.stringify(resp.data.menus));
          CommonUtils.setLocalStore(GlobalConsts.PermissionsKey,JSON.stringify(resp.data.permissions));
          this.menus = resp.data.menus;
          this.permissions = resp.data.permissions;
        }
      }
    }
  }
})
