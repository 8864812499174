const TokenKey = "itshizhancms-token";
const CurrentLoginUserName = "itshizhancms-sys-username"
const MenusKey = "itshizhancms-menus"
const PermissionsKey = "itshizhancms-permissions"

export const GlobalConsts = {
  DevBaseUrl: 'http://localhost:8181/',
  // DevBaseUrl: 'https://cmsapi.itshizhan.com/',
  ProdBaseUrl: 'https://cmsapi.itshizhan.com/',
  MenusKey,
  PermissionsKey,
  TokenKey,
  CurrentLoginUserName,
  AppProjects: [
    {
      id: 1,
      lable: '新手学前端',
      key: 'web'
    }, {
      id: 2,
      lable: '新手学Java',
      key: 'java'
    }, {
      id: 3,
      lable: '新手学Linux',
      key: 'linux'
    }, {
      id: 4,
      lable: '新手学数据库',
      key: 'database'
    }, {
      id: 7,
      lable: '新手学Golang',
      key: 'golang'
    }, {
      id: 5,
      lable: 'CS基础知识',
      key: 'csbasic'
    }, {
      id: 6,
      lable: '未分类',
      key: 'other'
    }
  ],
  DefaultVocabArticleSourceList: [
    {
      id: 1,
      lable: ' 鲁常英《经典英文枕边书：每天读点英语单词起源》',
      value: 1
    }, {
      id: 2,
      lable: ' 朱和中 《英汉汉英谚语格言警句词典》',
      value: 2
    }, {
      id: 3,
      lable: '《计算机专业英语（第3版）》-卜艳萍 周伟',
      value:3
    }, 
  ],
  RkyLevel: [
    '中级','高级'
  ],
  RkyMonth: [
    '5','11'
   ],
  RkyAmOrPm: [
   'AM','PM'
  ],
  highlightjs:'https://qiniufiles.itshizhan.com/md_editor_assests/highlight.min.js',
  mermaidjs:'https://qiniufiles.itshizhan.com/md_editor_assests/mermaid.min.js',
  katexjs:'https://qiniufiles.itshizhan.com/md_editor_assests/katex.min.css',
  katexcss:'https://qiniufiles.itshizhan.com/md_editor_assests/katex.min.css',
  atom_one_dark_css:'https://qiniufiles.itshizhan.com/md_editor_assests/atom-one-dark.css'
}
