<template>
  <!-- 参考： https://github.com/davidgrzyb/tailwind-login-template -->
  <div class="w-full flex flex-wrap">
    <!-- Image Section -->
    <div class="w-1/2 shadow-2xl flex bg-gray-100">
      <div class="object-cover  w-full h-screen hidden md:flex justify-center items-center flex-col">
        <img class="block w-52 h-52" src="../assets/images/itshizhan-qrcode.jpg" alt="扫描二维码">
        <h2 class="my-4">扫码二维码，关注公众号</h2>
        <p>回复【 <span class="font-bold">帮助</span> 】获取账号及技术支持</p>
      </div>
    </div>

    <!-- Login Section -->
    <div class="w-full md:w-1/2 flex flex-col">

      <div class="flex justify-center md:justify-start pt-12 md:pl-12 md:-mb-24">
        <a href="#" class="bg-black text-white font-bold text-xl p-4">cms.itshizhan.com</a>
      </div>

      <div class="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-24 lg:px-32">
        <p class="text-center text-3xl">欢迎使用ItshizhanCMS</p>
        <form class="flex flex-col pt-3 md:pt-8" onsubmit="event.preventDefault();">
          <div class="flex flex-col pt-4">
            <label for="email" class="text-lg">账号</label>
            <input type="email" v-model="form.username" placeholder="your@email.com or username"
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline">
          </div>

          <div class="flex flex-col pt-4">
            <label for="password" class="text-lg">密码</label>
            <input type="password" v-model="form.password" placeholder="Password"
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline">
          </div>
          <div class="flex flex-col pt-4">
            <label for="password" class="text-lg">图片验证码</label>
            <div class="flex items-center">
              <input type="text" v-model="form.captcha" placeholder="Captcha"
                class="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline">
              <img class="w-1/2 inline-block mx-10 cursor-pointer" :src="form.captchaUrl"
                style="width: 130px;height: 36px;" @click="getCaptcha()" />
            </div>
          </div>

          <input type="button" @click="doLogin" value="登 录"
            class="bg-blue-500 text-white font-bold text-lg hover:bg-blue-400 p-2 mt-8 cursor-pointer">
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import AuthService from '../apis/AuthService'
import SysMenuService from '../apis/SysMenuService';
import { GlobalConsts } from '../config'
import CommonUtils from '../utils/CommonUtils'
// import { useUserStore } from '../store/userStore'
const router = useRouter()
// const userStore = useUserStore()
let form = reactive({
  username: '',
  password: '',
  captcha: '',
  captchaUrl: '',
  captchaKey: ''
})
const getCaptcha = () => {
  AuthService.getCaptcha().then(res => {
    if (res.code == 1) {
      form.captchaUrl = res.data.captchaUrl;
      form.captchaKey = res.data.captchaKey
    }
  })
}
onMounted(() => {
  getCaptcha()
})
const doLogin = () => {
  //router.push("/")
  if (!form.username || !form.password || !form.captcha) {
    ElMessage.error("请填写用户名、密码或验证码！");
    return;
  }
  AuthService.login({
    username: form.username,
    password: form.password,
    captcha: form.captcha,
    captchaKey: form.captchaKey
  }).then(res => {
    if (res.code == 1) {
      // success data { token,username }
      CommonUtils.setLocalStore(GlobalConsts.TokenKey, res.data.token);
      CommonUtils.setLocalStore(GlobalConsts.CurrentLoginUserName, res.data.username);
      //userStore.setUsername(res.data.username)
      ElMessage.success("登录成功")
       // router.push("/")
       //重新获取菜单权限，覆盖本地缓存
       SysMenuService.getListByUser().then(resp=>{
        if (resp.code == 1) {
          CommonUtils.setLocalStore(GlobalConsts.MenusKey,JSON.stringify(resp.data.menus));
          CommonUtils.setLocalStore(GlobalConsts.PermissionsKey,JSON.stringify(resp.data.permissions));
          //强制刷新页面
          window.location.href = '/'
        }
      });
    } else {
      ElMessage.error(res.msg);
    }
  })
}
</script>
