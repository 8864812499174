<template>
  <div class="my-dashboard">
    <div>
      <h2 class="text-lg pt-2 font-bold text-warm-gray-700">首页</h2>
      <el-divider></el-divider>
    </div>
    <p class="mt-4">欢迎您，这是您的工作台！</p>
    <div class="flex mt-4">
      <router-link to="/dashboard/mymemo" class="bg-cyan-500 w-30 h-30 flex flex-col justify-center items-center">
        <img src="./imgs/memo-icon.png" class="w-16 h-16" />
        <div class="text-true-gray-100 font-bold flex justify-center items-center">我的备忘录</div>
      </router-link>
    </div>
  </div>
</template>

