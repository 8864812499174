import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import CommonUtils from '../utils/CommonUtils';
import DashboardIndex from '../views/dashboard/Index.vue'
import LayoutIndex from '../views/layout/Index.vue'
import Login from "../views/Login.vue";
import { useMenuStore } from '../store/menuStore'

/**
 * 白名单路由，不需要任何权限的路由
 */
const whiteList = ['/dashboard/index', '/dashboard/mymemo', '/setting/profile', '/help/index', '/nbstudy/note/chapter/add', 

  '/nbstudy/osps/chapter/add','/ruankaoyi/tiku/edit',
  '/nbstudy/columns/chapter/list',
  '/nbstudy/columns/article/edit',
  '/vocab/baseDict/edit'
  // 暂时不需要权限的路由
  ,'/nbstudycom/columns/edit'
  ,'/nbstudycom/columns/chapter/edit'
  ,'/nbstudycom/columns/chapter/list'
  ,'/nbstudycom/columns/article/edit'
  , '/nbstudycom/columns/article/list'
  , '/nbstudycom/columns/article/add'
  ,"/nbstudycom/note"
  ,"/nbstudycom/article/add"
  ,"/nbstudycom/course/edit"
  ,"/nbstudycom/course/chapter/list"
  ,"/nbstudycom/course/article/edit"
] 

//静态路由页面
export const staticRouter = [
  {
    name: 'LayoutIndex',
    path: '/',
    component: LayoutIndex,
    redirect: '/dashboard/index',
    children: [
      {
        name: 'DashboardIndex',
        path: 'dashboard/index',
        component: DashboardIndex,
      },
      {
        name: 'DashboardMemo',
        path: 'dashboard/mymemo',
        component: () => import('../views/dashboard/MyMemo.vue')
      },
      {
        name: 'Setting',
        path: 'setting/profile',
        component: () => import('../views/setting/Profile.vue')
      },
      {
        name: 'HelpIndex',
        path: 'help/index',
        component: () => import('../views/help/Index.vue')
      },
      {
        name: 'Page401',
        path: 'dashboard/401',
        component: () => import('../views/Page401.vue')
      }
    ]
  },
  // itshizhan，IT实战
  {
    name: 'NbStudy',
    path: '/nbstudy',
    component: LayoutIndex,
    children: [
      {
        name: 'NbStudyArticles',
        path: 'articles',
        component: () => import('../views/nbstudy/article/ArticleList.vue')
      },
      {
        name: 'NbStudyArticleTags',
        path: 'article/tags',
        component: () => import('../views/nbstudy/article/TagList.vue')
      },
      {
        name: 'NbStudyArticleAdd',
        path: 'article/add',
        component: () => import('../views/nbstudy/article/ArticleAdd.vue')
      },
      {
        name: 'NbStudyNotes',
        path: 'notes',
        component: () => import('../views/nbstudy/note/NoteList.vue')
      },
      {
        name: 'NbStudyNoteAdd',
        path: 'note/add',
        component: () => import('../views/nbstudy/note/NoteAdd.vue')
      },
      {
        name: 'NbStudyNoteChapterAdd',
        path: 'note/chapter/add',
        component: () => import('../views/nbstudy/note/NoteChapterContent.vue')
      },
      {
        name: 'NbStudyOsps',
        path: 'osps/list',
        component: () => import('../views/nbstudy/osps/OspsList.vue')
      },
      {
        name: 'NbStudyOspsAdd',
        path: 'osps/add',
        component: () => import('../views/nbstudy/osps/OspsAdd.vue')
      },
      {
        name: 'NbStudyOspsChapterAdd',
        path: 'osps/chapter/add',
        component: () => import('../views/nbstudy/osps/OspsChapterContent.vue')
      },
      {
        name: 'NbStudyTutorials',
        path: 'tutorials',
        component: () => import('../views/nbstudy/tutorial/TutorialList.vue')
      },
      
      {
        name: 'NbStudyTutorialAdd',
        path: 'tutorial/add',
        component: () => import('../views/nbstudy/tutorial/TutorialAdd.vue')
      },
      // columns
      {
        name: 'NbStudyColumns',
        path: 'columns',
        component: () => import('../views/nbstudy/columns/ColumnList.vue')
      },
      {
        name: 'NbStudyColumnsEdit',
        path: 'columns/edit',
        component: () => import('../views/nbstudy/columns/ColumnEdit.vue')
      },
      {
        name: 'NbStudyColumnChapterList',
        path: 'columns/chapter/list',
        component: () => import('../views/nbstudy/columns/ColumnChapterList.vue')
      },
      {
        name: 'NbStudyColumnArticleEdit',
        path: 'columns/article/edit',
        component: () => import('../views/nbstudy/columns/ColumnArticleEdit.vue')
      }
    ]
  },
  // nbstudycom 原itshizhan.com改版
  {
    name: 'NbStudyCom',
    path: '/nbstudycom',
    component: LayoutIndex,
    children: [
      {
        name: 'NbStudyComArticles',
        path: 'note',
        component: () => import('../views/nbstudycom/article/ArticleList.vue')
      },
      {
        name: 'NbStudyComArticleTags',
        path: 'article/tags',
        component: () => import('../views/nbstudycom/article/TagList.vue')
      },
      {
        name: 'NbStudyComArticleAdd',
        path: 'article/add',
        component: () => import('../views/nbstudycom/article/ArticleAdd.vue')
      },
      {
        name: 'NbStudyComNotes',
        path: 'notes',
        component: () => import('../views/nbstudycom/note/NoteList.vue')
      },
      {
        name: 'NbStudyComNoteAdd',
        path: 'note/add',
        component: () => import('../views/nbstudycom/note/NoteAdd.vue')
      },
      {
        name: 'NbStudyComNoteChapterAdd',
        path: 'note/chapter/add',
        component: () => import('../views/nbstudycom/note/NoteChapterContent.vue')
      },
      {
        name: 'NbStudyComOsps',
        path: 'osps/list',
        component: () => import('../views/nbstudycom/osps/OspsList.vue')
      },
      {
        name: 'NbStudyComOspsAdd',
        path: 'osps/add',
        component: () => import('../views/nbstudycom/osps/OspsAdd.vue')
      },
      {
        name: 'NbStudyComOspsChapterAdd',
        path: 'osps/chapter/add',
        component: () => import('../views/nbstudycom/osps/OspsChapterContent.vue')
      },
      {
        name: 'NbStudyComTutorials',
        path: 'tutorials',
        component: () => import('../views/nbstudycom/tutorial/TutorialList.vue')
      },      
      {
        name: 'NbStudyComTutorialAdd',
        path: 'tutorial/add',
        component: () => import('../views/nbstudycom/tutorial/TutorialAdd.vue')
      },
      // columns
      {
        name: 'NbStudyComCourse',
        path: 'course',
        component: () => import('../views/nbstudycom/course/CourseList.vue')
      },
      {
        name: 'NbStudyComCourseEdit',
        path: 'course/edit',
        component: () => import('../views/nbstudycom/course/CourseEdit.vue')
      },
      {
        name: 'NbStudyComCourseChapterList',
        path: 'course/chapter/list',
        component: () => import('../views/nbstudycom/course/CourseChapterList.vue')
      },
      {
        name: 'NbStudyComCourseArticleEdit',
        path: 'course/article/edit',
        component: () => import('../views/nbstudycom/course/CoourseArticleEdit.vue')
      }
    ]
  },
  //VocabPark
  {
    name: 'Vocab',
    path: '/vocab',
    component: LayoutIndex,
    children: [
      {
        name: 'VocabArticleList',
        path: 'article/list',
        component: () => import('../views/vocabpark/VocabArticleList.vue')
      },
      {
        name: 'VocabArticleAdd',
        path: 'article/add',
        component: () => import('../views/vocabpark/VocabArticleAdd.vue')
      },
      {
        name: 'VocabBaseDictList',
        path: 'baseDict/list',
        component: () => import('../views/vocabpark/BaseDictLit.vue')
      },
      {
        name: 'VocabBaseDictEdit',
        path: 'baseDict/edit',
        component: () => import('../views/vocabpark/BaseDictEdit.vue')
      },
      {
        name: 'NbStudyJdcRootAffixList',
        path: 'rootAffix/list',
        component: () => import('../views/vocabpark/RootAffixList.vue')
      },
      {
        name: 'NbStudyJdcRootAffixAdd',
        path: 'rootAffix/add',
        component: () => import('../views/vocabpark/RootAffixAdd.vue')
      },
      {
        name: 'NbStudyJdcArticleList',
        path: 'article/list',
        component: () => import('../views/vocabpark/VocabArticleList.vue')
      },
      {
        name: 'NbStudyJdcArticleAdd',
        path: 'article/add',
        component: () => import('../views/vocabpark/VocabArticleAdd.vue')
      },
      // Vocab----------end
    ]
  },
  // ruankayi start ================
  {
    name: 'Ruankaoyi',
    path: '/ruankaoyi',
    component: LayoutIndex,
    children: [
      {
        name: 'RuankaoyiTikuList',
        path: 'tiku',
        component: () => import('../views/ruankaoyi/TikuList.vue')
      },{
        name: 'RuankaoyiTikuEdit',
        path: 'tiku/edit',
        component: () => import('../views/ruankaoyi/TikuEdit.vue')
      }
    ]
  },
  // ruankayi end ================
  {
    name: 'SysRbac',
    path: '/sys/rbac',
    component: LayoutIndex,
    children: [
      {
        name: 'SysUser',
        path: 'user',
        component: () => import('../views/sys/rbac/SysUser.vue')
      },
      {
        name: 'SysRole',
        path: 'role',
        component: () => import('../views/sys/rbac/SysRole.vue')
      },
      {
        name: 'SysMenu',
        path: 'menu',
        component: () => import('../views/sys/rbac/SysMenu.vue')
      },
    ]
  },
  {
    name: 'Sys',
    path: '/sys',
    component: LayoutIndex,
    children: [
      {
        name: 'SysDict',
        path: 'dict',
        component: () => import('../views/sys/other/SysDict.vue')
      },
      {
        name: 'SysLog',
        path: 'log',
        component: () => import('../views/sys/other/SysLog.vue')
      }
    ]
  },
  {
    name: 'Login',
    path: '/login',
    component: Login
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes: staticRouter as RouteRecordRaw[]
})

//全局前置守卫, 路由权限与后台返回权限校验
router.beforeEach(async (to, from) => {
  const toPath = to.path;
  const mainMenu = useMenuStore();
  if (toPath == "/login" && CommonUtils.isLogin()) {
    return "/dashboard/index"
  } else if (toPath == "/login" && !CommonUtils.isLogin()) {
    return true;
  } else if (CommonUtils.isLogin()) {
    let menus = mainMenu.menus;
    if (menus.length == 0) {
      //console.log("-----------------当前没有菜单，设置菜单后检查权限------------------", toPath)
      //当前没有菜单，设置菜单
      await mainMenu.setMenus();
      //检查当前访问路由是否有权限
      //mainMenu.setMenus(); 是异步的
      const isTrue = validHasPathPermission(mainMenu.menus, toPath);
      console.log("-----------------当前没有菜单，设置菜单后检查权限--------after:", isTrue)
      if (isTrue || toPath == "/dashboard/401") {
        return true;
      } else {
        return "/dashboard/401"
      }
    } else {
      //console.log("-----------------当前有菜单,检查权限------------------", menus, toPath)
      //检查当前访问路由是否有权限
      const isTrue = validHasPathPermission(menus, toPath);
      console.log("-----------------当前有菜单,检查权限--------after:", isTrue)
      if (isTrue || toPath == "/dashboard/401") {
        return true;
      } else {
        return "/dashboard/401"
      }
    }
  } else {
    return "/login"
  }
})



//@ts-ignore
const validHasPathPermission = (menus, toPath): boolean => {
  //console.log("------validHasPathPermission-----------", menus, toPath)
  let isTrue = false;
  if (whiteList.includes(toPath)) {
    isTrue = true;
  } else {
    for (let i = 0; i < menus.length; i++) {
      if (menus[i].path == toPath) {
        isTrue = true;
        break;
      } else {
        let arr = menus[i].children;
        for (let j = 0; j < arr.length; j++) {
          if (arr[j].path == toPath) {
            isTrue = true;
            break;
          }
        }
      }
    }
  }
  return isTrue;
}
export default router

