
       if (typeof window !== 'undefined') {
         function loadSvg() {
           var body = document.body;
           var svgDom = document.getElementById('__svg__icons__dom__');
           if(!svgDom) {
             svgDom = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
             svgDom.style.position = 'absolute';
             svgDom.style.width = '0';
             svgDom.style.height = '0';
             svgDom.id = '__svg__icons__dom__';
             svgDom.setAttribute('xmlns','http://www.w3.org/2000/svg');
             svgDom.setAttribute('xmlns:link','http://www.w3.org/1999/xlink');
           }
           svgDom.innerHTML = "<symbol class=\"icon\" viewBox=\"0 0 1024 1024\"  id=\"icon-menu-fold\"><path d=\"M96 160h832q32 0 32 32t-32 32H96q-32 0-32-32t32-32ZM394.667 373.333H928q32 0 32 32t-32 32H394.667q-32 0-32-32t32-32ZM394.667 586.667H928q32 0 32 32t-32 32H394.667q-32 0-32-32t32-32ZM96 800h832q32 0 32 32t-32 32H96q-32 0-32-32t32-32ZM80.917 527.808l145.686 98.859a32 32 0 0 0 49.962-26.475V402.475a32 32 0 0 0-49.962-26.454L80.917 474.86a32 32 0 0 0 0 52.949z\" /></symbol><symbol class=\"icon\" viewBox=\"0 0 1024 1024\"  id=\"icon-menu-unfold\"><path d=\"M96 160h832q32 0 32 32t-32 32H96q-32 0-32-32t32-32ZM394.667 373.333H928q32 0 32 32t-32 32H394.667q-32 0-32-32t32-32ZM394.667 586.667H928q32 0 32 32t-32 32H394.667q-32 0-32-32t32-32ZM96 800h832q32 0 32 32t-32 32H96q-32 0-32-32t32-32ZM258.88 527.808l-145.664 98.859a32 32 0 0 1-49.984-26.475V402.475a32 32 0 0 1 49.984-26.454L258.88 474.86a32 32 0 0 1 0 52.949z\" /></symbol><symbol viewBox=\"0 0 48 48\" fill=\"none\"  id=\"icon-pin\"><g clip-path=\"url(#icon-pin_a)\"><path d=\"M10.696 17.504c2.639-2.638 5.774-2.565 9.182-.696L32.62 9.745l-.721-4.958L43.213 16.1l-4.947-.71-7.074 12.73c1.783 3.638 1.942 6.544-.697 9.182l-7.778-7.778L6.443 41.556l11.995-16.31-7.742-7.742Z\" stroke=\"currentColor\" stroke-width=\"4\" stroke-linejoin=\"round\" /></g><defs><clipPath id=\"icon-pin_a\"><path fill=\"#333\" d=\"M0 0h48v48H0z\" /></clipPath></defs></symbol>";
           body.insertBefore(svgDom, body.lastChild);
         }
         if(document.readyState === 'loading') {
           document.addEventListener('DOMContentLoaded', loadSvg);
         } else {
           loadSvg()
         }
      }
        
export default {}