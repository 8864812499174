import HttpUtils from '../utils/HttpUtils'

export default {
  getList() {
    return HttpUtils.get("/admin/sys/menu/list")
  },
  /**
   * 获取当前登录用户的菜单列表，不需要传递参数，token即可
   * @returns 
   */
  getListByUser() {
    return HttpUtils.get("/admin/sys/menu/listByUser")
  },
  addMenu(data: {
    parentId: string | number,
    name: string,
    path: string,
    code: string,
    type: string|number,
    sortNum?: number | string,
    state?: number | string,
  }) {
    return HttpUtils.post("/admin/sys/menu/save", data)
  },
  updateMenu(data: {
    id: string,
    parentId: string | number,
    name: string,
    path: string,
    code: string,
    type: string|number,
    sortNum?: number | string,
    state?: number | string,
  }) {
    return HttpUtils.post("/admin/sys/menu/update", data)
  },
  getUserInfo() {
    return HttpUtils.get("/admin/sys/user/info")
  },
  getUserInfoById(userId: string) {
    return HttpUtils.get("/admin/sys/user/info/" + userId)
  },
  getUserInfoByPage(data: {
    pageNum: number,
    pageSize: number,
    username?: string
  }) {
    return HttpUtils.post("/admin/sys/user/list",data)
  },
  deleteById(userId: string) {
    return HttpUtils.get(`/admin/sys/user/delete/${userId}`)
  },
  getParentIdListById(id: string){
    return HttpUtils.get(`/admin/sys/menu/getParentIdListById?id=${id}`)
  }
}

