import axios, { AxiosRequestConfig } from 'axios';
import CommonUtils from '../utils/CommonUtils'
import { GlobalConsts } from '../config';

//默认 Content-Type: application/json，如果需要处理，可以在这里进行配置
//axios.defaults.headers
console.log("------------------------------", import.meta.env.AXIOS_BASE_URL)
// 创建实例
const axiosInstance = axios.create({
  // 同意前缀，方便生产环境nginx转发到
  //baseURL:import.meta.env.AXIOS_BASE_URL as string,
  // send cookies when cross-domain requests
  baseURL: import.meta.env.DEV ? GlobalConsts.DevBaseUrl : GlobalConsts.ProdBaseUrl,
  withCredentials: true,
  timeout: 50000,
});

// request interceptor
axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    // 可以进行请求拦截处理，如添加请求头，token 等
    //config.headers?[GlobalConsts.TokenKey] = CommonUtils.getLocalStore(GlobalConsts.TokenKey)
    if(CommonUtils.getLocalStore(GlobalConsts.TokenKey)) {
      //headers是Record类型，不建议直接 config.headers?[GlobalConsts.TokenKey] 赋值
      config.headers = {
        [GlobalConsts.TokenKey]: CommonUtils.getLocalStore(GlobalConsts.TokenKey)!
      }
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);
// response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    const res = response.data;
    console.log("====================",res)
    if (res.code == 20001) {
      //认证异常，需要重新登录
      CommonUtils.clearLocalStore();
      if(window.location.pathname !== "/login"){
        window.location.href = "/login"
      } 
    }
    return Promise.resolve(res);
  },
  (error) => {
    //TODO:在此处理服务器异常或自定义错误异常
    //console.log('err' + error.response.status);
    return Promise.reject(error);
  }
);

interface ResulutData {
  code: number;
  msg?: string;
  // eslint-disable-next-line
  data?: any;
}

interface RequstType {
  (url: string, params?: object, config?: AxiosRequestConfig): Promise<ResulutData>;
}

// 封装 get 方法，类型为Get
const get: RequstType = async (url) => {
  return await axiosInstance.get(url);
};

// 封装 get 方法，类型为Get
const post: RequstType = async (url, params) => {
  return await axiosInstance.post(url, params);
};

const postUpload: RequstType = async (url, params) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" }
  };
  return await axiosInstance.post(url, params,config);
};

const HttpUtils = {
  get,
  post,
  postUpload
};

// 导出封装后的axios
export default HttpUtils;
